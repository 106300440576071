



























































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");

@Component
export default class HelpAndSupport extends Vue {

    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getPortalAuthTokenUrl!: string;

    private watchProductSelection: any;
    private watchAccountSelection: any;
    private watchGasAgreementSelection: any;

    private productText = "gas";

    private invoicingView = false;
    private meteringView = false;
    private consumptionView = false;

    private siteAddressUrl = "";
    private billingAddressUrl = "";

    private refreshHelpAndSupport(): void {

        const routeName = this.$route.name;

        if (routeName === 'Metering') {
            this.meteringView = true;
            this.invoicingView = false;
            this.consumptionView = false;
        } else if (routeName === 'Invoicing') {
            this.invoicingView = true;
            this.meteringView = false;
            this.consumptionView = false;
        } else if (routeName === 'Consumption') {
            this.consumptionView = true;
            this.invoicingView = false;
            this.meteringView = false;
            
        }

        if (this.getSelectedProduct == "Power") {
            this.productText = "power";
        }
        else {
            this.productText = "gas";

            this.setManageAccountLinks();
        }
    }

    private setManageAccountLinks() {

        let portalUrl = this.getPortalAuthTokenUrl;

        portalUrl = `${portalUrl}&accountNum=${this.getSelectedAccount}&siteRefNum=${this.getSelectedSiteRefNumber}`;

        this.siteAddressUrl = `${portalUrl}&chosenController=Site&chosenAction=Address`;
        this.billingAddressUrl = `${portalUrl}&chosenController=Billing&chosenAction=ChangeAddress`;

    }

    async mounted() {

        this.refreshHelpAndSupport();

        this.watchProductSelection = this.$store.watch((state, getters) => getters["Products/getSelectedProduct"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();

        });


        this.watchAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();
        });


        this.watchGasAgreementSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();
        });

    }
}
